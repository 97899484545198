import { animateCameraToBottom, animateCameraToTop, camera, scene, isAnimatingCamera } from './script.js';
import { animateCameraToBottom_investors, animateCameraToTop_investors, isAnimatingCamera_investors } from './investors.js';
import { animateCameraToBottom_gamers, animateCameraToTop_gamers, isAnimatingCamera_gamers } from './gamers.js';
import { animateCameraToBottom_gamedevs, animateCameraToTop_gamedevs, isAnimatingCamera_gameDevs } from './gamedevs.js';
import Lenis from 'lenis'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isMobile } from './pages.js';
let scrollType = 0; // Track if the user can scroll
let currentSection = 'top'; // Track which section is currently visible
let currentModule = null;
let bottomSectionHeight = 0;
gsap.registerPlugin(ScrollTrigger);
// Define camera start and end positions
const cameraStartY = -7;
let cameraEndY = -34;
let documentLenght;
let isScrollable = false;
export async function initScroll() {
    document.body.style.overflowY = 'hidden'; // Disable vertical scrolling initially

    console.log('scrolling initialized');
    console.log(document.getElementById('bottom-section').offsetHeight);
    documentLenght = document.getElementById('bottom-section').offsetHeight;
    document.getElementById('scroll-to-bottom').addEventListener('click', scrollToBottomHandler);
    document.getElementById('scroll-to-top').addEventListener('click', scrollToTopHandler);
    // Add wheel event listener directly to window for bottom section
    if (!isMobile()) {
        window.addEventListener('wheel', (event) => {
            event.preventDefault();
            onBottomSectionScroll(event);
        }, { passive: false });
        // Add another wheel event listener directly to window for top section
        window.addEventListener('wheel', (event) => {
            event.preventDefault();
            onTopSectionScroll(event);
        }, { passive: false });
    }

    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    if (isTouchDevice) {
        //disable touch completely
        document.body.style.touchAction = 'none';
        

        window.addEventListener('touchstart', (e) => {
            startY = e.touches[0].clientY;
        });

        window.addEventListener('touchmove', (e) => {
            let currentY = e.touches[0].clientY;
            let deltaY = startY - currentY;

            if (deltaY > 50) {
                document.getElementById('scroll-to-bottom').click();
            } else if (deltaY < -50 && camera.position.y === -7 && !isAnimatingCamera) {
                document.getElementById('scroll-to-top').click();
            }
        });
    } else {
        // Initialize Lenis for desktop
    }
    InitLenis();

}


const lenis = new Lenis();
initScroll();
export let cameraProgress = 0;

function InitLenis() {

    lenis.on('scroll', ({ scroll, limit, progress }) => {
        if (progress >= 0){

            cameraProgress = progress;
        }
        console.log(cameraProgress);
        if (isScrollable) {
            if (isMobile()) {
                camera.position.y = gsap.utils.interpolate(cameraStartY, cameraEndY, progress);
                console.log('Mobile camera position:', camera.position.y);
            }
            ScrollTrigger.update();
        }
    });

    gsap.ticker.add((time) => {
        lenis.raf(time * 1000); // Convert time from seconds to milliseconds
    });

    gsap.ticker.lagSmoothing(0);
    setTimeout(() => {
        lenis.resize();
        window.dispatchEvent(new Event('resize'));

    }, 300);
}
function InitLenis_2() {

    lenis.stop();
    setTimeout(() => {
        lenis.resize();
        window.dispatchEvent(new Event('resize'));
        lenis.start();
        document.body.style.touchAction = 'auto';

    }, 300);
}
function scaleContent() {
    //move the camera back based on the window height
    //if (window.innerHeight < 1000) {
    //    const heightDiff = 1000 - window.innerHeight;
    //    const yOffset = Math.min(heightDiff / 300, 1); // scales down by max 1 unit for screen heights down to 700px
    //    camera.position.set(0, 1.5, 4.5 + yOffset * 5);
    //}
}

window.addEventListener('resize', scaleContent);
window.addEventListener('load', scaleContent);


function scrollToBottomHandler() {
    if (currentSection === 'top' && !isAnimatingCamera && !isAnimatingCamera_investors && !isAnimatingCamera_gamers && !isAnimatingCamera_gameDevs) {
        console.log('scrolling to bottom');
        smoothScrollToBottom();
        document.body.style.overflowY = 'auto'; // Enable vertical scrolling
        if (scrollType === 0) {
            console.log('scrolling to bottom home');
            isScrollable = true;
            animateCameraToBottom();
            window.addEventListener('scroll', onScroll_00);
            bottomSectionHeight = document.getElementById('bottom-section').offsetHeight;
            console.log('Bottom section height:', bottomSectionHeight);
            if (isMobile()) {
                cameraEndY = -44;
            } else {
                cameraEndY = -34;
            }
        }
        if (scrollType === 1) {
            isScrollable = true;
            console.log('scrolling to bottom investors');
            animateCameraToBottom_investors();
            window.addEventListener('scroll', onScroll_01);
            cameraEndY = -32;
        }
        if (scrollType === 2) {
            isScrollable = true;
            console.log('scrolling to bottom gamers');
            animateCameraToBottom_gamers();
            window.addEventListener('scroll', onScroll_02);
            cameraEndY = -30.51;
        }
        if (scrollType === 3) {
            isScrollable = true;
            console.log('scrolling to bottom gamedevs');
            animateCameraToBottom_gamedevs();
            window.addEventListener('scroll', onScroll_03);
            cameraEndY = -27;
        }

        // Create a GSAP timeline
        if (isScrollable) {
            const cameraTimeline = gsap.timeline({
                scrollTrigger: {
                    trigger: '#bottom-section',
                    start: 'top top',
                    end: 'bottom bottom',
                    scrub: true,
                    ease: 'linear', // Adjust easing to control scroll speed
                    onUpdate: self => {
                        console.log('Scroll progress:', cameraProgress);
                    }
                }
            });

            // Animate the camera position
            cameraTimeline.to(camera.position, {
                y: cameraEndY,
                ease: 'none'
            });
        }
        InitLenis_2();
        if (isMobile()){
            //document.getElementById('bottom-section').style.overscrollBehavior = 'none';
        }
    }
}



function scrollToTopHandler() {
    if (currentSection === 'bottom' && !isAnimatingCamera && !isAnimatingCamera_investors && !isAnimatingCamera_gamers && !isAnimatingCamera_gameDevs) {
        smoothScrollToTop();
        document.body.style.overflowY = 'hidden'; // Disable vertical scrolling
        if (scrollType === 0) {
            animateCameraToTop();
            window.removeEventListener('scroll', onScroll_00);
        }
        if (scrollType === 1) {
            animateCameraToTop_investors();
            window.removeEventListener('scroll', onScroll_01);
        }
        if (scrollType === 2) {
            animateCameraToTop_gamers();
            window.removeEventListener('scroll', onScroll_02);
        }
        if (scrollType === 3) {
            animateCameraToTop_gamedevs();
            window.removeEventListener('scroll', onScroll_03);
        }
    }
}

function onScroll_00() {
    // Calculate scroll progress


}
function onScroll_01() { // INVESTORS


}

function onScroll_02() { // GAMERS

}

function onScroll_03() { // GAME DEVS

}


export function SetIsScrollable(module, value) {
    console.log('setting scroll type to ' + value);
    scrollType = value;
    currentModule = module;
    switch (value) {
        case 0:
            window.removeEventListener('scroll', onScroll_00);
            break;
        case 1:
            window.removeEventListener('scroll', onScroll_01);
            break;
        case 2:
            window.removeEventListener('scroll', onScroll_02);
            break;
        case 3:
            window.removeEventListener('scroll', onScroll_03);
            break;
        default:
            console.error('Type not implemented')
    }
}
function smoothScrollToBottom() {
    if (currentSection === 'bottom') return;
    currentSection = 'bottom';
    console.log('scrolling to bottom');
    document.getElementById('top-section').style.transform = 'translateY(-100%)';
    document.getElementById('bottom-section').style.transform = 'translateY(0)';
    document.getElementById('navbar').style.transform = 'translateY(-130%)';
    if (isMobile()) {
        document.getElementById('bottom-section').style.height = documentLenght;
        console.log('documentLenght:', documentLenght);
    }
}

export function smoothScrollToTop() {
    if (currentSection === 'top') return;
    currentSection = 'top';
    console.log('scrolling to top');
    document.getElementById('bottom-section').style.transform = 'translateY(100%)';
    document.getElementById('top-section').style.transform = 'translateY(0)';
    document.getElementById('navbar').style.transform = 'translateY(0)';
    lenis.stop();
}


// Variables to track scroll deltas
let scrollDeltaTop = 0;
let scrollDeltaBottom = 0;
let touchStartYTop = null;
let touchStartYBottom = null;

// Function to handle wheel scroll on top section
function onTopSectionScroll(event) {

    console.log('onTopSectionScroll called');
    if (currentSection !== 'top') return;

    if (event.deltaY > 0) {
        // Scrolling down
        scrollDeltaTop += event.deltaY;
    } else {
        // Scrolling up
        scrollDeltaTop = 0;
    }

    console.log('Top Section Scroll Delta:', scrollDeltaTop);

    // Threshold to trigger scroll to bottom
    const threshold = 50; // Adjust as needed
    console.log(scrollDeltaTop);
    if (scrollDeltaTop >= threshold) {
        scrollDeltaTop = 0;
        document.getElementById('scroll-to-bottom').click();
    }
}




// Function to handle wheel scroll on bottom section
function onBottomSectionScroll(event) {
    if (currentSection !== 'bottom') return;

    const bottomSection = event.currentTarget;
    console.log('bottomSection.scrollTop:', bottomSection.scrollTop);

    // Check if the user is at the top of the bottom section
    if (camera.position.y >= -7.4 && !isAnimatingCamera) {
        if (event.deltaY < 0) {
            // Scrolling up
            scrollDeltaBottom += -event.deltaY;
        } else {
            // Scrolling down
            scrollDeltaBottom = 0;
        }

        console.log('Bottom Section Scroll Delta:', scrollDeltaBottom);

        const threshold = 50; // Adjust as needed

        if (scrollDeltaBottom >= threshold) {
            scrollDeltaBottom = 0;
            document.getElementById('scroll-to-top').click();
        }
    } else {
        // Not at the top; reset scroll delta
        scrollDeltaBottom = 0;
    }
}
