import * as THREE from 'three';
import { resetCameraPosition } from './pages';
import { createPortalPlane } from './portals';
import { importBlurryGlass, importBlurryGlassGradient, importGLTFAnimatedModel, animateModelsOnHover } from './importModels';
import { GamersRocketURL } from './importModels';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

let renderer, scene, camera;
// geometry
const geometry = new THREE.BoxGeometry();
// materials
const material = new THREE.MeshStandardMaterial({ color: 0x00ff00 });
// meshes
const cube = new THREE.Mesh(geometry, material);
const parameters = {}
export let isAnimatingCamera_gamers = false;
parameters.size = 1
let galaxyMaterial;
const positionsArray = [];
const clock = new THREE.Clock();
const gltfLoader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.7/');
dracoLoader.setDecoderConfig({ type: 'js' });
gltfLoader.setDRACOLoader(dracoLoader);

const animatedButtons = [];
// Create the custom ShaderMaterial
galaxyMaterial = new THREE.ShaderMaterial({
    uniforms: {
        uMouse: { value: new THREE.Vector3() },
        uTime: { value: 0 },
    },
    vertexShader: `
            // Add 'galaxyOffset' as an attribute
            attribute vec3 galaxyOffset;

            uniform vec3 uMouse;
            uniform float uTime;

            void main() {
                // Calculate the world position by adding the galaxy offset
                vec3 worldPosition = position + galaxyOffset;

                // Use worldPosition for distance and direction calculations
                float dist = distance(worldPosition, uMouse);
                float displacement = 100.0 * exp(-dist * 0.01);
                vec3 direction = normalize(worldPosition - uMouse);
                vec3 newPosition = worldPosition + direction * displacement;

                vec4 mvPosition = modelViewMatrix * vec4(newPosition, 1.0);
                gl_PointSize = 1.0 * (300.0 / -mvPosition.z);
                gl_Position = projectionMatrix * mvPosition;
            }
        `,
    fragmentShader: `
            void main() {
                float d = length(gl_PointCoord - vec2(0.5));
                if (d > 0.5) discard;
                gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0 - d * 2.5);
            }
        `,
    transparent: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    alphaTest: 0.5,
});

const generateGalaxy = (size, offset, particleCount) => {
    /**
     * Geometry
     */
    const geometry = new THREE.BufferGeometry();
    const positions = new Float32Array(particleCount * 3);
    const galaxyOffsets = new Float32Array(particleCount * 3);

    for (let i = 0; i < particleCount; i++) {
        const i3 = i * 3;

        // Generate positions without adding the offset
        positions[i3] = (Math.random() - 0.5) * size.x; // x
        positions[i3 + 1] = (Math.random() - 0.5) * size.y; // y
        positions[i3 + 2] = (Math.random() - 0.5) * size.z; // z

        // Set the galaxy offset for each particle
        galaxyOffsets[i3] = offset.x;
        galaxyOffsets[i3 + 1] = offset.y;
        galaxyOffsets[i3 + 2] = offset.z;
    }
    positionsArray.push(positions);
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('galaxyOffset', new THREE.BufferAttribute(galaxyOffsets, 3));
    const points = new THREE.Points(geometry, galaxyMaterial);
    scene.add(points);
};
let elapsedTime;
let rocketModel, Model2, Model3;
// INITIALIZATION
export async function initNewScene(FromScene, FromRenderer, FromCamera) {
    fadeInCanavas();
    await new Promise((resolve) => { setTimeout(resolve, 500); });
    resetCameraPosition(FromCamera);
    elapsedTime = 0;

    // Your scene setup code
    scene = FromScene;
    renderer = FromRenderer;
    camera = FromCamera;
    generateGalaxy(new THREE.Vector3(100, 100, 100), new THREE.Vector3(0, 0, 0), 1500)
    const directionalLight1 = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight1.position.set(1, 1, 1).normalize();
    scene.add(directionalLight1);
    cube.position.set(0, 0.8, -0.2);
    const cube2 = cube.clone();
    cube2.position.set(-3, 0.8, -0.2);
    scene.add(cube2);
    rocketModel = await importGLTFAnimatedModel(GamersRocketURL, new THREE.Vector3(3,0.6,0), new THREE.Vector3(0.1,0.1,0.1),gltfLoader);
    scene.add(rocketModel);
    animatedButtons.push(rocketModel);
    // Model2 = await importGLTFAnimatedModel(GamersRocketURL, new THREE.Vector3(-3,0,0), new THREE.Vector3(0.1,0.1,0.1),gltfLoader);
    // scene.add(Model2);
    // Model3 = await importGLTFAnimatedModel(GamersRocketURL, new THREE.Vector3(0,0,0), new THREE.Vector3(0.1,0.1,0.1),gltfLoader);
    // scene.add(Model3);
    //setup the portals:
    // Portal scene and camera
    createPortals();
    scene.add(cube);
    renderer.setAnimationLoop(animate);
}



// Declare arrays to hold portals and cameras
let portals = [];
let portalCameras = [];
function createPortals() {
    const portalPlaneAspect = 4 / 2.6; // width / height of the portal plane
    const portalPlaneDimensions = { width: 5.5, height: 3 };
    const loader = new THREE.TextureLoader();

    const yStart = -10.5; // Starting Y position
    const yStep = -3; // Step between each portal on the Y-axis
    const xPositions = [2.5, -2.5]; // Alternating X positions (right and left)

    // URLs for the textures
    const cubeTextures = [
        'https://i.imgur.com/EnD4hQd.jpg', // px
        'https://i.imgur.com/tUboeCr.jpg', // nx
        'https://i.imgur.com/nVMAfLw.jpg', // py
        'https://i.imgur.com/k6UxiiZ.jpg', // ny
        'https://i.imgur.com/yEOMzQg.jpg', // pz
        'https://i.imgur.com/GhEpGuR.jpg', // nz
    ];

    const portalTextureURLs = [
        new URL('../assets/img/Scene_map.png', import.meta.url),
        new URL('../assets/img/MushroomRender.png', import.meta.url),
        new URL('../assets/img/Ithris.png', import.meta.url),
        new URL('../assets/img/Valley.png', import.meta.url),
        new URL('../assets/img/Watermills.png', import.meta.url),
    ];

    // Prepare portal data
    const portalsData = [
        // First portal with cube textures
        {
            position: new THREE.Vector3(xPositions[0], yStart, -1),
            textureType: 'cube',
            textures: cubeTextures,
            cameraFov: 800,
        },
        // Remaining portals with equirectangular textures
        ...portalTextureURLs.map((textureURL, index) => ({
            position: new THREE.Vector3(
                xPositions[(index + 1) % 2],
                yStart + yStep * (index + 1),
                -1
            ),
            textureType: 'equirectangular',
            textureURL: textureURL,
            cameraFov: 100,
        })),
    ];

    // Create portals
    portalsData.forEach((portalData) => {
        const portalScene = new THREE.Scene();

        const portalCamera = new THREE.PerspectiveCamera(
            portalData.cameraFov,
            portalPlaneAspect,
            0.01,
            10
        );
        portalCamera.position.z = 1;
        portalCameras.push(portalCamera);

        // Set up the background for the portalScene
        if (portalData.textureType === 'cube') {
            portalScene.background = new THREE.CubeTextureLoader().load(
                portalData.textures
            );
        } else if (portalData.textureType === 'equirectangular') {
            // Load the texture
            const textureURL = new URL(portalData.textureURL, import.meta.url);

            loader.load(
                textureURL.href,
                function (texture) {
                    texture.mapping = THREE.EquirectangularRefractionMapping;
                    texture.colorSpace = THREE.SRGBColorSpace;
                    portalScene.background = texture;
                },
                undefined,
                function (err) {
                    console.error(
                        'An error occurred loading the texture:',
                        err
                    );
                }
            );
        }

        // Create the portal plane
        const portal = createPortalPlane(renderer, portalScene, portalCamera, {
            width: portalPlaneDimensions.width,
            height: portalPlaneDimensions.height,
            position: portalData.position,
        });
        portals.push(portal);

        scene.add(portal.portalPlane);
    });
}


function fadeInCanavas() {
    gsap.to('canvas', {
        opacity: 1,
        duration: 0.5,
        delay: 1 // Starts after the camera animation completes
    });
}
const mouseWorldPosition = new THREE.Vector3();
const mouseposition = new THREE.Vector2();

window.addEventListener('mousemove', function (e) {
    mouseposition.x = (e.clientX / window.innerWidth) * 2 - 1;
    mouseposition.y = - (e.clientY / window.innerHeight) * 2 + 1; // Corrected calculation
});


const raycaster = new THREE.Raycaster();

function animate(time) {
    const deltaTime = clock.getDelta();

    // Your animation code
    cube.rotation.x += 0.01;
    cube.rotation.y += 0.01;
    elapsedTime = clock.getElapsedTime();
    // safely animate camera
    if (isAnimatingCamera_gamers) {
        let t = (elapsedTime - cameraAnimationStartTime) / cameraAnimationDuration;
        if (t >= 1) {
            t = 1;
            isAnimatingCamera_gamers = false;
        }

        // Interpolate camera position
        camera.position.lerpVectors(cameraStartPosition, cameraEndPosition, t);

        // Interpolate camera rotation
        camera.quaternion.slerpQuaternions(cameraStartQuaternion, cameraEndQuaternion, t);
    }

    let closestPortal = null;
    let previousClosestIndex = -1;
    let minDistance = Infinity;
    
    portals.forEach((portal, index) => {
        const distance = camera.position.distanceTo(portal.portalPlane.position);
        if (distance < minDistance) {
            minDistance = distance;
            closestPortal = { portal, index };
        }
    });
    
    // Check if the closest portal has changed
    const closestIndex = closestPortal ? closestPortal.index : -1;
    if (closestIndex !== previousClosestIndex) {
        // Smoothly interpolate from the previous closest camera's rotation to the new one
        const targetCamera = portalCameras[closestIndex];
        const previousCamera = portalCameras[previousClosestIndex];
    
        if (previousCamera && targetCamera) {
            targetCamera.rotation.x = THREE.MathUtils.lerp(previousCamera.rotation.x, mouseposition.x, 0.1);
            targetCamera.rotation.y = THREE.MathUtils.lerp(previousCamera.rotation.y, mouseposition.y, 0.1);
        }
    
        // Update the previous closest index
        previousClosestIndex = closestIndex;
    }
    
    // Render all portals with rotation adjustments
    portals.forEach((portal, index) => {
        const portalCamera = portalCameras[index];
    
        if (closestIndex === index) {
            // Move the portal camera based on the mouse position with direct control
            portalCamera.rotation.x = -mouseposition.y/3;
            portalCamera.rotation.y = mouseposition.x;
    
            // Render the closest portal with smooth interpolation
            portal.renderPortal();
        } else {
            // Set rotation for other portals and render them
            portalCamera.rotation.y = 0.5 * Math.cos(elapsedTime / 2);
            portal.renderPortal();
        }
    });
    
    animateModelsOnHover(animatedButtons, deltaTime, raycaster, mouseposition, camera);
    // Render the portal scene to the texture
    const vector = new THREE.Vector3(mouseposition.x, mouseposition.y, 0.5); // z = 0.5 for middle of the frustum
    vector.unproject(camera);
    mouseWorldPosition.copy(vector);
    // Update the shader uniforms
    if (galaxyMaterial) {
        galaxyMaterial.uniforms.uMouse.value.copy(mouseWorldPosition);
        galaxyMaterial.uniforms.uTime.value = elapsedTime;
    }
    renderer.render(scene, camera);
}

let cameraAnimationStartTime = 0;
let cameraAnimationDuration = 0.5; // Duration in seconds
let cameraStartPosition = new THREE.Vector3();
let cameraEndPosition = new THREE.Vector3();
let cameraStartQuaternion = new THREE.Quaternion();
let cameraEndQuaternion = new THREE.Quaternion();

export function animateCameraToBottom_gamers() {
    //log
    console.log('Animating camera to bottom');
    if (isAnimatingCamera_gamers) return;

    isAnimatingCamera_gamers = true;
    cameraAnimationStartTime = clock.getElapsedTime();

    // Store the starting position and rotation
    cameraStartPosition.copy(camera.position);
    cameraStartQuaternion.copy(camera.quaternion);

    // Define the end position and rotation
    cameraEndPosition.set(0, -7, 4); // Move down and closer
    camera.lookAt(0, 1.5, 0); // Adjust to look at a lower point
    camera.updateMatrixWorld();
    cameraEndQuaternion.copy(camera.quaternion);

    // Reset camera to start position and rotation
    camera.position.copy(cameraStartPosition);
    camera.quaternion.copy(cameraStartQuaternion);
}
export function animateCameraToTop_gamers() {
    console.log('Animating camera to top');
    if (isAnimatingCamera_gamers) return;
    isAnimatingCamera_gamers = true;
    cameraAnimationStartTime = clock.getElapsedTime();

    // Store the starting position and rotation
    cameraStartPosition.copy(camera.position);
    cameraStartQuaternion.copy(camera.quaternion);

    // Define the end position and rotation (original position)
    cameraEndPosition.set(0, 1.5, 4.5); // Original position
    camera.lookAt(0, -7.5, 0); // Original lookAt
    camera.updateMatrixWorld();
    cameraEndQuaternion.copy(camera.quaternion);

    // Reset camera to start position and rotation
    camera.position.copy(cameraStartPosition);
    camera.quaternion.copy(cameraStartQuaternion);
}