// portalPlane.js

import * as THREE from 'three';

function createPortalPlane(renderer, portalScene, portalCamera, options = {}) {
  const target = new THREE.WebGLRenderTarget(1024, 512);

  const width = options.width || 1;
  const height = options.height || 1;
  const position = options.position || new THREE.Vector3(0, 0, 0);
  const rotation = options.rotation || new THREE.Euler(0, 0, 0);
  const radius = options.radis || 1;    // Radius for the rounded corners
  const curvatureAmount = options.curvature || 0.5; // Amount to curve the plane


  const material = new THREE.ShaderMaterial({
    vertexShader: `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      uniform sampler2D target;
      varying vec2 vUv;
      void main() {
        gl_FragColor = texture2D(target, vUv);
      }
    `,
    uniforms: {
      target: { value: target.texture },
    },
  });

  
  // Create the rounded rectangle shape
  const shape = createRoundedRectShape(width, height, radius);
  const geometry = new THREE.ShapeGeometry(shape);
  
  // Access the position attribute and modify the Z positions to add curvature
  const positionAttribute = geometry.attributes.position;
  const positions = positionAttribute.array;
  
  // Create an array to hold the UV coordinates
  const uvs = new Float32Array((positions.length / 3) * 2); // UV has 2 components per vertex
  
  for (let i = 0, j = 0; i < positions.length; i += 3, j += 2) {
    const x = positions[i];
    const y = positions[i + 1];
    
    // Normalize x and y to the range [0, 1] for UV mapping
    const u = (x + width / 2) / width;
    const v = (y + height / 2) / height;
  
    // Apply planar UV mapping
    uvs[j] = u;
    uvs[j + 1] = v;
  
    // Apply curvature by modifying the Z position
    const t = (x + width / 2) / width;
    const zOffset = Math.sin(t * Math.PI) * curvatureAmount;
    positions[i + 2] = zOffset; // Modify the Z axis for curvature
  }
  
  // Update the position attribute
  positionAttribute.needsUpdate = true;
  
  // Set the UVs as an attribute on the geometry
  geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
  const portalPlane = new THREE.Mesh(geometry, material);

  portalPlane.position.copy(position);
  portalPlane.rotation.copy(rotation);


  // Function to create a shape with rounded corners
  function createRoundedRectShape(width, height, radius) {
    const x = -width / 2;
    const y = -height / 2;
    const shape = new THREE.Shape();
    shape.moveTo(x + radius, y);
    shape.lineTo(x + width - radius, y);
    shape.quadraticCurveTo(x + width, y, x + width, y + radius);
    shape.lineTo(x + width, y + height - radius);
    shape.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    shape.lineTo(x + radius, y + height);
    shape.quadraticCurveTo(x, y + height, x, y + height - radius);
    shape.lineTo(x, y + radius);
    shape.quadraticCurveTo(x, y, x + radius, y);
    return shape;
  }
  function renderPortal() {
    const currentRenderTarget = renderer.getRenderTarget();

    renderer.setRenderTarget(target);
    renderer.render(portalScene, portalCamera);

    renderer.setRenderTarget(currentRenderTarget);
  }

  return { portalPlane, renderPortal };
}

export { createPortalPlane };

