import * as THREE from 'three';
import Splide from '@splidejs/splide';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { importSocialDiscord, importBlurryGlass, importPortraitBase, importGLTFAnimatedModel, animateModelsOnHover } from './importModels.js';
import { InvestorsModelURL, socialDiscordURL, portraitBaseURL, LogoInstaModelURL, LogoDiscordModelURL, LogoFacebookModelURL, GamingHeartModelURL, LogoLinkedinModelURL, importPortraitPartners, StonksModelURL } from './importModels.js';
import { initScroll } from './scrolldown.js';
import { gsap } from 'gsap/gsap-core';
import { resetCameraPosition, fadeInCanavas, createRoundedPlane } from './pages.js';
import { cameraProgress } from './scrolldown.js';
import { importBlurryGlass } from './importModels.js';
import { cubeMapNode } from 'three/src/nodes/utils/CubeMapNode.js';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import { create } from '@splidejs/splide/src/js/utils';


let renderer, scene, camera, cssRenderer, cssScene;
const gltfLoader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.7/');
dracoLoader.setDecoderConfig({ type: 'js' });
gltfLoader.setDRACOLoader(dracoLoader);
export let isAnimatingCamera_investors = false;

const mouseWorldPosition = new THREE.Vector3();
const clock = new THREE.Clock();
let elapsedTime = 0;
const animatedButtons = [];

const DiscordMaterial = new THREE.ShaderMaterial({
    uniforms: {
        time: { value: 0 },
        color: { value: new THREE.Color(0x5865F2) } // Discord's official blurple color
    },
    vertexShader: `
    varying vec2 vUv;
    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
        `,
    fragmentShader: `
        uniform float time;
        uniform vec3 color;
        varying vec2 vUv;
        void main() {
            float pulse = 0.5 + 0.5 * sin(time * 2.0); // Pulsing effect
            vec3 finalColor = color * pulse;
            gl_FragColor = vec4(finalColor, 1.0);
            }
            `,
    transparent: true
});

const parameters = {}
parameters.size = 1
const positionsArray = [];
// Create the custom ShaderMaterial
let galaxyMaterial;
galaxyMaterial = new THREE.ShaderMaterial({
    uniforms: {
        uMouse: { value: new THREE.Vector3() },
        uTime: { value: 0 },
    },
    vertexShader: `
            // Add 'galaxyOffset' as an attribute
            attribute vec3 galaxyOffset;

            uniform vec3 uMouse;
            uniform float uTime;

            void main() {
                // Calculate the world position by adding the galaxy offset
                vec3 worldPosition = position + galaxyOffset;

                // Use worldPosition for distance and direction calculations
                float dist = distance(worldPosition, uMouse);
                float displacement = 100.0 * exp(-dist * 0.01);
                vec3 direction = normalize(worldPosition - uMouse);
                vec3 newPosition = worldPosition + direction * displacement;

                vec4 mvPosition = modelViewMatrix * vec4(newPosition, 1.0);
                gl_PointSize = 1.0 * (300.0 / -mvPosition.z);
                gl_Position = projectionMatrix * mvPosition;
            }
        `,
    fragmentShader: `
            void main() {
                float d = length(gl_PointCoord - vec2(0.5));
                if (d > 0.5) discard;
                gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0 - d * 2.5);
            }
        `,
    transparent: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    alphaTest: 0.5,
});

const generateGalaxy = (size, offset, particleCount) => {
    /**
     * Geometry
     */
    const geometry = new THREE.BufferGeometry();
    const positions = new Float32Array(particleCount * 3);
    const galaxyOffsets = new Float32Array(particleCount * 3);

    for (let i = 0; i < particleCount; i++) {
        const i3 = i * 3;

        // Generate positions without adding the offset
        positions[i3] = (Math.random() - 0.5) * size.x; // x
        positions[i3 + 1] = (Math.random() - 0.5) * size.y; // y
        positions[i3 + 2] = (Math.random() - 0.5) * size.z; // z

        // Set the galaxy offset for each particle
        galaxyOffsets[i3] = offset.x;
        galaxyOffsets[i3 + 1] = offset.y;
        galaxyOffsets[i3 + 2] = offset.z;
    }
    positionsArray.push(positions);
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('galaxyOffset', new THREE.BufferAttribute(galaxyOffsets, 3));
    const points = new THREE.Points(geometry, galaxyMaterial);
    scene.add(points);
};

const fontLoader = new FontLoader();

// MODELS
let title_Discord, title_Insta, gamingHeart;
// INITIALIZATION
export async function initNewScene(FromScene, FromRenderer, FromCamera, FromCssRenderer, FromCssScene) {
    // scene setup code
    //wait 0.5 seconds before resizing

    fadeInCanavas();
    await new Promise((resolve) => { setTimeout(resolve, 1000); });
    scene = FromScene;
    renderer = FromRenderer;
    camera = FromCamera;
    cssRenderer = FromCssRenderer;
    cssScene = FromCssScene;
    //add a directional light
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(1, 1, 1).normalize();
    scene.add(directionalLight);
    directionalLight.castShadow = true;
    directionalLight.shadow.bias = -0.001;
    //add a point light
    const pointLight = new THREE.PointLight(0xffffff, 1, 200);
    pointLight.position.set(0, 0.5, -3);
    pointLight.castShadow = true;
    pointLight.shadow.bias = -0.001;
    //add helper for light
    resetCameraPosition(FromCamera);
    scene.add(pointLight);
    const arealight = new THREE.AmbientLight(0xffffff, 0.1);
    scene.add(arealight);
    // Load the font and add texts
    //load3DTexts();
    
    generateGalaxy(new THREE.Vector3(100, 30, 40), new THREE.Vector3(0, 5, -20), 1000);
    generateGalaxy(new THREE.Vector3(100, 30, 40), new THREE.Vector3(0, -5, -20), 1000);
    generateGalaxy(new THREE.Vector3(100, 5, 40), new THREE.Vector3(0, 0, -20), 1500);
    generateGalaxy(new THREE.Vector3(100, 50, 40), new THREE.Vector3(0, -15, -20), 1500);
    //const orbit = new OrbitControls(camera, renderer.domElement);
    //orbit.update();
    //Set up the splide carousel
    
    createLabel(
        new THREE.Vector3(3, -12, 0),
        'Mercato',
        'Il mercato del gaming è uno dei settori di intrattenimento più importanti e in rapida crescita al mondo. Oltre $300 miliardi: è la stima di Accenture del valore diretto e indiretto di questo mercato.'
    );
    
    createLabel(
        new THREE.Vector3(0, -12, 0),
        'Crowdfunding',
        'Le possibilità di guadagno sono collegate direttamente alle vendite dei giochi che hai finanziato, permettendoti di ottenere una percentuale dei ricavi.'
    );
    
    createLabel(
        new THREE.Vector3(-3, -12, 0),
        'Tutela investitore',
        'Diminuiamo i rischi per gli investitori attraverso una selezione rigorosa dei progetti, fondi sbloccati solo al raggiungimento del goal e il supporto di esperti durante lo sviluppo.'
    );

    const cubeMesh1 = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), new THREE.MeshBasicMaterial({ color: 0x00ff00 }));
    cubeMesh1.position.set(3, -10, 0);
    const cubeMesh2 = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), new THREE.MeshBasicMaterial({ color: 0x00ff00 }));
    cubeMesh2.position.set(0, -10, 0);
    const cubeMesh3 = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), new THREE.MeshBasicMaterial({ color: 0x00ff00 }));
    cubeMesh3.position.set(-3, -10, 0);
    scene.add(cubeMesh1, cubeMesh2, cubeMesh3);


    const splid = new Splide('.splide', {
        type: 'loop',
        perPage: 1,  // Show one full card and part of another on each side
        perMove: 1,
        gap: '2rem',  // Increase the gap to allow more visual spacing
        arrows: false,
        pagination: false,
        focus: 'center',  // Focus the active card in the center
        breakpoints: {
          768: {
            perPage: 1.2, // Adjust slightly for mobile view
          },
          1024: {
            perPage: 1.5, // Adjust for tablet view
          },
        },
      }).mount();
    document.getElementById('prev').onclick = function () {
        console.log('prev');
        splid.go('<');
    }
    document.getElementById('next').onclick = function () {
        console.log('next');
        splid.go('>');
    }
    setupBusinessModel();



    //import partners
    const partners = await importPortraitPartners(new THREE.Vector3(-3, -31, -0.5), new THREE.Vector3(0.8, 0.8, 0.8), cssScene);
    //add light
    const light = new THREE.PointLight(0xffffff, 20, 100);
    light.position.set(0, -30, 5);
    scene.add(light);


    
    renderer.setAnimationLoop(animate);
}

const BusinessCards = [];
const BusinessTexts = {
    'Investimento sul progetto': [ 'Il crowdfunding revenue share si caratterizza per la possibilità di investire su giochi specifici e ottenere una parte dei profitti generati dal loro successo sul mercato.', 'Lorem Ipsum' ],
    'Crowdfunding Revenue share': [ 'Le possibilità di guadagno sono collegate direttamente alle vendite dei giochi che hai finanziato, permettendoti di ottenere una percentuale dei ricavi.', 'Lorem Ipsum' ],
    'Lorem ipsum dolor sit': ['Vestibulum congue leo eget neque pulvinar, nec pharetra mauris dapibus. Suspendisse potenti.', 'Lorem Ipsum'],
    'Quanto si guadagna?': ['Il guadagno è proporzionale al successo del gioco finanziato. Più il gioco vende, più guadagni.', 'Lorem Ipsum'],
    'Tutela investitore': ['Diminuiamo i rischi per gli investitori attraverso una selezione rigorosa dei progetti, fondi sbloccati solo al raggiungimento del goal e il supporto di esperti durante lo sviluppo.', 'Lorem Ipsum'],
}
function setupBusinessModel() {
    const startPosition = { x: 0, y: -17, z: 0 }; // Starting position for the entire layout
    const totalRowWidth = 8; // Total width for each row (adjust as necessary)
    const padding = 0.5; // Padding between items

    // Define the number of items per row
    const rowConfig = [3, 2]; // First row has 3 items, second row has 2 items

    let index = 0;
    for (let row = 0; row < rowConfig.length; row++) {
        const itemsInRow = rowConfig[row];
        const itemWidth = totalRowWidth/itemsInRow; // Calculate the width of each item

        for (let col = 0; col < itemsInRow; col++) {
            const position = calculatePosition(startPosition, row, col, itemWidth, padding, itemsInRow);
            const [title, textArray] = Object.entries(BusinessTexts)[index];
            createBusinessLabel(position, itemWidth, title, textArray[0], textArray[1]);
            index++;
        }
    }
}

// Function to calculate position dynamically for each item
function calculatePosition(startPosition, row, col, itemWidth, padding, itemsInRow) {
    const offsetX = itemWidth + padding; // Horizontal distance, considering padding
    const offsetY = -3.5; // Vertical distance between rows

    return {
        x: startPosition.x + (col - (itemsInRow - 1) / 2) * offsetX, // Center the items around startPosition
        y: startPosition.y + row * offsetY,
        z: startPosition.z
    };
}

// Create each label with a CSS3DObject on a Three.js plane
function createBusinessLabel(position, width, title, text, buttonText) {
    // Create the HTML content for each card
    const cardDiv = document.createElement('div');
    cardDiv.className = 'business-card boxedTitle';
    cardDiv.style.width = `${width * 100}px`; // Adjust the width of the text container based on the plane width
    cardDiv.innerHTML = `
    <span>
        <h3>${title}</h3>
        <p>${text}</p>
        <h2>${buttonText}</h2>
    </span>
    `;

    // Convert HTML content to CSS3DObject
    const cssObject = new CSS3DObject(cardDiv);
    cssObject.position.set(position.x, position.y, position.z);
    cssObject.scale.set(0.01, 0.01, 0.01); // Scale down the object
    cssScene.add(cssObject);
    BusinessCards.push(cssObject);
}

// Function to create a rounded plane
function createRoundedPlane(width, height, radius, thickness) {
    const geometry = new THREE.PlaneGeometry(width, height);
    const material = new THREE.MeshBasicMaterial({ color: 0x333333, transparent: true, opacity: 0.8 });
    const plane = new THREE.Mesh(geometry, material);
    return plane;
}

function createLabel(position, title, text) {
    // Create the container div
    var div = document.createElement('div');
    div.className = 'whyCard boxedTitle';
    div.style.pointerEvents = 'auto';   
    div.style.cursor = 'pointer';
    
    // Create the h2 element with class 'boxedTitle'
    var h2 = document.createElement('h2');
    h2.textContent = title;
    div.appendChild(h2);
    // Create the p element
    var p = document.createElement('p');
    p.textContent = text;
    div.appendChild(p);
    
    // Create the CSS3DObject
    var label = new CSS3DObject(div);
    label.position.copy(position);
    label.scale.set(0.01, 0.01, 0.01);
    // Add the label to the scene
    cssScene.add(label);
}

















let cameraAnimationStartTime = 0;
let cameraAnimationDuration = 0.5; // Duration in seconds
let cameraStartPosition = new THREE.Vector3();
let cameraEndPosition = new THREE.Vector3();
let cameraStartQuaternion = new THREE.Quaternion();
let cameraEndQuaternion = new THREE.Quaternion();
function load3DTexts() {
    fontLoader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', function (font) {
        // Big text properties
        const bigTextSize = .7;
        const bigTextColor = 0xffffff;

        // Small text properties
        const smallTextSize = 0.3;
        const smallTextColor = 0xaaaaaa;

        // Positions
        const positions = [
            { x: -4.5, y: -1, z: 0 }, // Left
            { x: 0, y: -1, z: 0 }, // Center
            { x: 4.5, y: -1, z: 0 } // Right
        ];

        // Text content
        const texts = [
            {
                big: '+15.4%',
                small: 'Market growth'
            },
            {
                big: '2.5 Bln',
                small: '  Gamers \nworldwide'
            },
            {
                big: '21Mln',
                small: '       Employees in the \n     videogame industry'
            }
        ];

        // Add texts to the scene
        for (let i = 0; i < texts.length; i++) {
            // Big text
            addText(
                texts[i].big,
                bigTextSize,
                new THREE.Vector3(positions[i].x, positions[i].y + 0.6, positions[i].z),
                bigTextColor,
                font
            );

            // Small text (positioned slightly below the big text)
            addText(
                texts[i].small,
                smallTextSize,
                new THREE.Vector3(positions[i].x, positions[i].y - 0.6, positions[i].z),
                smallTextColor,
                font
            );
            importBlurryGlass(new THREE.Vector3(4, 2.5, 0.25), new THREE.Vector3(positions[i].x, positions[i].y, positions[i].z - 0.15), scene);
        }

    }, undefined, function (error) {
        console.error('An error occurred while loading the font:', error);
    });
}

export function animateCameraToBottom_investors() {
    //log
    console.log('Animating camera to bottom');
    if (isAnimatingCamera_investors) return;

    isAnimatingCamera_investors = true;
    cameraAnimationStartTime = clock.getElapsedTime();

    // Store the starting position and rotation
    cameraStartPosition.copy(camera.position);
    cameraStartQuaternion.copy(camera.quaternion);

    // Define the end position and rotation
    cameraEndPosition.set(0, -7, 4); // Move down and closer
    camera.lookAt(0, 1.5, 0); // Adjust to look at a lower point
    camera.updateMatrixWorld();
    cameraEndQuaternion.copy(camera.quaternion);

    // Reset camera to start position and rotation
    camera.position.copy(cameraStartPosition);
    camera.quaternion.copy(cameraStartQuaternion);
}
export function animateCameraToTop_investors() {
    if (isAnimatingCamera_investors) return;

    isAnimatingCamera_investors = true;
    cameraAnimationStartTime = clock.getElapsedTime();

    // Store the starting position and rotation
    cameraStartPosition.copy(camera.position);
    cameraStartQuaternion.copy(camera.quaternion);

    // Define the end position and rotation (original position)
    cameraEndPosition.set(0, 1.5, 4.5); // Original position
    camera.lookAt(0, -7.5, 0); // Original lookAt
    camera.updateMatrixWorld();
    cameraEndQuaternion.copy(camera.quaternion);

    // Reset camera to start position and rotation
    camera.position.copy(cameraStartPosition);
    camera.quaternion.copy(cameraStartQuaternion);
}
const goldMaterial = new THREE.MeshPhysicalMaterial({
    color: 0x00ff55,
    emissive: 0xff0000,
    roughness: 0.1
});



const mouseposition = new THREE.Vector2();

window.addEventListener('mousemove', function (e) {
    mouseposition.x = (e.clientX / window.innerWidth) * 2 - 1;
    mouseposition.y = - (e.clientY / window.innerHeight) * 2 + 1; // Corrected calculation
});
const raycaster = new THREE.Raycaster();
function animatePlanesBasedOnMouse() {
    BusinessCards.forEach(function(plane) {
        // Clone plane's position
        let planePosition = plane.position.clone();

        // Project plane position to NDC
        planePosition.project(camera);

        // Calculate distance between mouse and plane in NDC
        let dx = mouseposition.x - planePosition.x;
        let dy = mouseposition.y - planePosition.y;
        let distance = Math.sqrt(dx * dx + dy * dy);

        // Define an effect based on distance
        // For example, scale the plane inversely proportional to distance
        let scale = 1.1 - distance;
        scale = THREE.MathUtils.clamp(scale, 0.9, 1); // Clamp scale between 0.5 and 1
        plane.scale.set(scale/100, scale/100, scale/100);

        // Optionally, rotate the plane towards the mouse
        plane.rotation.y = dx * Math.PI * 0.05; // Adjust the multiplier for effect strength

        // Optionally, adjust the opacity based on distance (if materials support transparency)
        if (plane.material && plane.material.opacity !== undefined) {
            plane.material.opacity = scale;
            plane.material.transparent = true;
        }
    });
}
// Animation loop
let animationLoopRunning = false;
let cameraProgressDelta = 0;
let previousCameraProgress = 0;
function animate() {
    //calculate cameraProgress Delta


    deltaTime = clock.getDelta();
    //console.log(deltaTime);
    DiscordMaterial.uniforms.time.value += clock.getDelta();

    // Update the mixer for any animations
    const delta = clock.getDelta();
    //if (title_Discord.userData.mixer) {
    //    title_Discord.userData.mixer.update(delta);
    //}
    elapsedTime = clock.getElapsedTime();
    // safely animate camera
    if (isAnimatingCamera_investors) {
        let t = (elapsedTime - cameraAnimationStartTime) / cameraAnimationDuration;
        if (t >= 1) {
            t = 1;
            isAnimatingCamera_investors = false;
        }

        // Interpolate camera position
        camera.position.lerpVectors(cameraStartPosition, cameraEndPosition, t);

        // Interpolate camera rotation
        camera.quaternion.slerpQuaternions(cameraStartQuaternion, cameraEndQuaternion, t);
    }
    // Update the shader uniforms
    const vector = new THREE.Vector3(mouseposition.x, mouseposition.y, 0.5); // z = 0.5 for middle of the frustum
    vector.unproject(camera);
    mouseWorldPosition.copy(vector);
    if (galaxyMaterial) {
        galaxyMaterial.uniforms.uMouse.value.copy(mouseWorldPosition);
        galaxyMaterial.uniforms.uTime.value = elapsedTime;
    }
    animateModelsOnHover(animatedButtons, deltaTime, raycaster, mouseposition, camera);
    animatePlanesBasedOnMouse();
    renderer.render(scene, camera);
    cssRenderer.render(cssScene, camera);
}

let pointLightHelpers = [];
let pointLights = [];
let additionalPointLightHelper;
let additionalPointLight;

// Variable to track if the animation has started
let animationStarted = false;

// Function to convert your coordinate system to Three.js coordinates
function convertPosition(userX, userY, z) {
    // In your system:
    // userX: vertical position (up/down), x-axis in Three.js
    // userY: horizontal position (left/right), y-axis in Three.js
    // z remains the same
    return new THREE.Vector3(userX, userY, z);
}


