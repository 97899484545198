import * as THREE from 'three';


export function resetCameraPosition(camera, object) {
    camera.position.set(0, 1.5, 4.5);
    camera.lookAt(0, 1, 0);
    if (object) {
        camera.lookAt(object.position);
    }
}
export function fadeInCanavas() {
    gsap.to('canvas', {
        opacity: 1,
        duration: 0.5,
        delay: 1 // Starts after the camera animation completes
    });
}
export function createRoundedPlane(width, height, radius) {
    const shape = new THREE.Shape();

    // Start from the top-left corner and move clockwise
    shape.moveTo(-width / 2 + radius, height / 2);
    shape.lineTo(width / 2 - radius, height / 2);
    shape.quadraticCurveTo(width / 2, height / 2, width / 2, height / 2 - radius);
    shape.lineTo(width / 2, -height / 2 + radius);
    shape.quadraticCurveTo(width / 2, -height / 2, width / 2 - radius, -height / 2);
    shape.lineTo(-width / 2 + radius, -height / 2);
    shape.quadraticCurveTo(-width / 2, -height / 2, -width / 2, -height / 2 + radius);
    shape.lineTo(-width / 2, height / 2 - radius);
    shape.quadraticCurveTo(-width / 2, height / 2, -width / 2 + radius, height / 2);

    // Extrude the shape
    const geometry = new THREE.ShapeGeometry(shape);

    return geometry;
}
export function isMobile() {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

  